<template>
  <div class="h-full">
    <loader :loading="loading" :backdrop="true" />
    <div class="form-row w-full">
      <FinalForm
        ref="form"
        @submit="saveData"
        :initialValues="initialValues"
        class="w-full pr-2"
      >
        <div class="stick bg-white pb-3 z-10">
          <div class="form-row">
            <label class="font-semibold" style="color: var(--highlightColor500)"
              >lead hazard/misc.
            </label>
          </div>
        </div>
        <div>
          <label class="form-section-sub-title"
            >LEAD HAZARD: Lessor's Disclosure</label
          >
          <div class="text-title">
            presence of lead-based paint and/or lead-based hazards (check only
            one box):
          </div>
          <div class="h-4"></div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall type="checkbox" name="noPaintCheckbox" />
            </button>

            <div class="text-title" name="noPaint">
              lessor has no knowledge of lead-based paint in the house.
            </div>
          </div>

          <div class="form-row">
            <button>
              <CheckboxInputSmall type="checkbox" name="withPaintCheckbox" />
            </button>

            <div class="text-title">
              lessor knows that lead-based paint are present in the house
              (explain)
            </div>
          </div>
          <div class="form-row ml-4">
            <text-input
              id="paintDesc"
              v-model="paintDesc"
              rows="5"
              multiline
            />
          </div>
          <div class="text-title">
            records and reports available to lessor (check only one box):
          </div>
          <div class="h-4"></div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall type="checkbox" name="noRecordsCheckbox" />
            </button>

            <div class="text-title">
              lessor has no reports pertaining to lead-based paint in the
              housing.
            </div>
          </div>

          <div class="form-row">
            <button>
              <CheckboxInputSmall type="checkbox" name="withRecordsCheckbox" />
            </button>

            <div class="text-title">
              Lessor has reports or records indicating presence of lead-based
              paint (explain)
            </div>
          </div>
          <div class="form-row ml-4">
            <text-input
              id="recordDesc"
              v-model="recordDesc"
              rows="5"
              multiline
            />
          </div>
        </div>
      </FinalForm>
      <modal-footer :footer="footer" :primary="saveData"></modal-footer>
    </div>
  </div>
</template>

<script>
import ModalNavigation from "@/mixins/ModalNavigation";
import Loader from "@/components/ui/Loader";
import CheckboxInputSmall from "@/components/form/CheckboxInputSmall";
import { FinalForm } from "vue-final-form";
import InitializeFormMixin from "@/components/form/InitializeFormMixin";
import ModalFooter from "@/components/ui/modals/ModalFooter";
import NotifyMixin from "@/mixins/NotifyMixin";
import TextInput from '@/components/ui/TextInput';

export default {
  mixins: [ModalNavigation, InitializeFormMixin, NotifyMixin],
  components: {
    Loader,
    FinalForm,
    CheckboxInputSmall,
    ModalFooter,
    TextInput,
  },
  data() {
    return {
      noPaintCheckbox: "",
      withPaintCheckbox: "",
      noRecordsCheckbox: "",
      withRecordsCheckbox: "",
      paintDesc: "",
      recordDesc: "",
      footer: {
        primaryButton: "save",
      },
    };
  },
  computed: {
    initialValues() {
      return {
        paintDesc: "",
        recordDesc: "",
        noPaintCheckbox: "",
        withPaintCheckbox: "",
        noRecordsCheckbox: "",
        withRecordsCheckbox: "",
      };
    },
  },
  methods: {
    saveData() {
      this.$refs.form.formState.values.paintDesc = this.paintDesc;
      this.$refs.form.formState.values.recordDesc = this.recordDesc;

      if (
        !this.$refs.form.formState.values.noPaintCheckbox &&
        !this.$refs.form.formState.values.withPaintCheckbox &&
        !this.$refs.form.formState.values.withRecordsCheckbox &&
        !this.$refs.form.formState.values.noRecordsCheckbox
      ) {
        this.notifyError("Please select one checkbox from both sections.");
      }
    },
  },
};
</script>

<style scoped>
input:checked {
  accent-color: var(--highlightColor500) !important;
}

.stick {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
button {
  outline: none;
  box-shadow: none;
}
</style>

